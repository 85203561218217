import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Deload Week`}</em></strong></p>
    <p>{`Front Rack/Clean Mobility`}</p>
    <p>{`Technique work: Hang Cleans (empty bar)`}</p>
    <p>{`then,`}</p>
    <p>{`4 rounds for time:`}</p>
    <p>{`500M Row`}</p>
    <p>{`25-Medicine Ball Cleans (20/14)`}</p>
    <p>{`25/side-Medicine Ball Russian Twists (20/14)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      